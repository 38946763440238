import React, { useEffect } from "react";
import CartItem from "./CartItem";
import { Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"; // Added import
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../../State/Cart/Action";

function Cart() {
  const navigate = useNavigate();
  const { cart } = useSelector((store) => store);
  const dispatch = useDispatch();

  console.log("cart from cart: ", cart);

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch, cart.updateCartItem, cart.deleteCartItem]); // Added dispatch to dependencies

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <div className="bg-yellow-500 w-full h-24 mb-8 flex items-center px-4 lg:px-14 relative">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="text-black absolute left-4 lg:left-14 focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          aria-label="Go back"
        >
          <ArrowCircleLeftIcon
            sx={{ fontSize: 40 }} // Increased font size from 30 to 40
            className="text-black mr-2"
          />
        </button>

        {/* Cart Title */}
        <span className="text-black font-bold text-3xl lg:text-6xl mx-auto flex items-center">
          CART
          <ShoppingCartIcon className="text-4xl lg:text-6xl ml-2" />
        </span>

        {/* Placeholder for alignment */}
        <div className="hidden lg:block" style={{ width: "30px" }}></div>
      </div>

      {/* Cart Items and Price Details */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 px-4 lg:px-16 flex-1">
        {/* Cart Items */}
        <div className="col-span-2">
          {cart.cart?.cartItems?.length > 0 ? (
            cart.cart.cartItems.map((item) => (
              <CartItem key={item.id} item={item} />
            ))
          ) : (
            <p className="text-center text-gray-600">Your cart is empty.</p>
          )}
        </div>

        {/* Price Details */}
<div className="mt-5 lg:mt-0 lg:sticky lg:top-20">
  <div className="border p-6 bg-gray-50 rounded-lg shadow-lg">
    <p className="uppercase text-center text-2xl font-bold pb-4 text-gray-800">
      Price Details
    </p>
    <Divider className="my-4" />

    <div className="space-y-4 font-semibold mt-4">
      <div className="flex justify-between text-base lg:text-lg text-gray-700">
        <span>Price</span>
        <span>₹{cart.cart?.totalPrice}</span>
      </div>
      <div className="flex justify-between text-base lg:text-lg text-gray-700">
        <span>Discount</span>
        <span className="text-green-600">- ₹{cart.cart?.discount}</span>
      </div>
      <div className="flex justify-between text-base lg:text-lg text-gray-700">
        <span>Delivery Charge</span>
        <span className="text-green-600">Free</span>
      </div>
      <div className="flex justify-between text-lg lg:text-xl font-bold text-gray-800 border-t border-gray-200 pt-4">
        <span>Total Price</span>
        <span className="text-green-600">
          ₹{cart.cart?.totalDiscountedPrice}
        </span>
      </div>
    </div>

    <Button
      variant="contained"
      className="w-full mt-8 rounded-lg shadow-md hover:shadow-lg transition duration-200"
      sx={{
        px: "2.5rem",
        py: "0.8rem",
        bgcolor: "#e68a00",
        color: "black",
        fontWeight: "bold",
        fontSize: "16px",
        "&:hover": {
          bgcolor: "#d07c00",
        },
      }}
      onClick={() => navigate("/checkout?step=1")}
    >
      Check Out
    </Button>
  </div>
</div>

      </div>

  
    </div>
  );
}

export default Cart;
