import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import React from "react";
import AddressCard from "../AddressCard/AddressCard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../../../State/Order/Action";
import { ToastContainer, toast } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";
// const indianStates = [
//   "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
//   "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand",
//   "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
//   "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
//   "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
//   "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands",
//   "Chandigarh", "Dadra and Nagar Haveli", "Daman and Diu", "Lakshadweep",
//   "Delhi", "Puducherry"
// ];

const indianStates = ["Rajasthan"];

function DeliveryAddressForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, order } = useSelector((store) => store);

  console.log("user from delivery address: ", auth?.user?.address);

  // Function to handle "Deliver Here" for saved addresses
  const handleDeliveryHere = (add) => {
    const address = {
      firstName: add?.firstName,
      lastName: add?.lastName,
      streetAddress: add?.streetAddress,
      city: "Jodhpur",
      state: "Rajasthan",
      zipCode: "342037",
      mobile: add?.mobile,
    };
    console.log("item", address);
    dispatch(createOrder({ address, navigate }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const zipCode = "342037";
    const mobile = data.get("phoneNumber");

    // Validation for zip code and mobile number
    if (zipCode.length !== 6) {
      toast.error("Zip Code must be 6 digits.");
      return;
    }

    if (mobile.length !== 10) {
      toast.error("Mobile Number must be 10 digits.");
      return;
    }

    const address = {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      streetAddress: data.get("address"),
      city: data.get("city"),
      state: data.get("state"),
      zipCode: zipCode,
      mobile: mobile,
    };

    dispatch(createOrder({ address, navigate }));
  };

  return (
    <>
      {order.loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Grid container spacing={4}>
            {/* Address Section */}

            {auth?.user?.address && auth.user.address.length > 0 && (
              <Grid
                item
                xs={12}
                lg={5}
                className="border rounded-md shadow-md h-[30.5rem] overflow-y-scroll bg-gray-50"
              >
                <div className="p-5 py-7 border-b text-gray-800">
                  {auth.user.address.map((item, index) => (
                    <div key={index} className="mb-6">
                      <AddressCard address={item} />
                      <Button
                        sx={{
                          mt: 2,
                          bgcolor: "RGB(145 85 253)",
                          color: "white",
                          fontWeight: "bold",
                          "&:hover": { bgcolor: "#8f4fff" },
                        }}
                        size="large"
                        variant="contained"
                        onClick={() => handleDeliveryHere(item)}
                      >
                        Deliver Here
                      </Button>
                    </div>
                  ))}
                </div>
              </Grid>
            )}

            {/* Form Section */}
            <Grid item xs={12} lg={7}>
              <Box className="border rounded-md shadow-md p-6 bg-white">
                <form onSubmit={handleSubmit}>
                  <p className="text-2xl font-bold text-gray-800 mb-4">
                    Delivery Details
                  </p>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        fullWidth
                        autoComplete="given-name"
                        InputProps={{ style: { fontSize: "1rem" } }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        fullWidth
                        autoComplete="family-name"
                        InputProps={{ style: { fontSize: "1rem" } }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        id="address"
                        name="address"
                        label="Hostel and Room No."
                        fullWidth
                        autoComplete="street-address"
                        multiline
                        rows={4}
                        InputProps={{ style: { fontSize: "1rem" } }}
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="city"
                        name="city"
                        label="City"
                        fullWidth
                        autoComplete="address-level2"
                        InputProps={{ style: { fontSize: "1rem" } }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required>
                        <InputLabel id="state-label">State</InputLabel>
                        <Select
                          labelId="state-label"
                          id="state"
                          name="state"
                          label="State"
                          sx={{ fontSize: "1rem" }}
                        >
                          {indianStates.map((state, index) => (
                            <MenuItem
                              key={index}
                              value={state}
                              sx={{ fontSize: "1rem" }}
                              disabled={state === "Rajasthan"} // Disable Rajasthan option
                            >
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="zip"
                        name="zip"
                        label="Zip/Postal Code"
                        fullWidth
                        autoComplete="postal-code"
                        InputProps={{ style: { fontSize: "1rem" } }}
                        value={342037}
                        disabled
                      />
                    </Grid> */}

                    <Grid item xs={12}>
                      <TextField
                        required
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        fullWidth
                        autoComplete="tel"
                        InputProps={{ style: { fontSize: "1rem" } }}
                        placeholder="Enter 10 digit mobile number"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Button
                        sx={{
                          py: 1.5,
                          mt: 2,
                          bgcolor: "RGB(145 85 253)",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          "&:hover": {
                            bgcolor: "#8f4fff",
                          },
                        }}
                        size="large"
                        variant="contained"
                        type="submit"
                      >
                        Deliver Here
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default DeliveryAddressForm;
