import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import logo from "./logo.png";

import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import { navigation } from "./navigation";
import { useNavigate } from "react-router-dom";
import AuthModel from "../../../Auth/AuthModel";
import { getUser, logout } from "../../../../State/Auth/Action";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../../../State/Cart/Action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  console.log("path", location.pathname);
  const [open, setOpen] = useState(false);
  const { auth, cart } = useSelector((store) => store);

  console.log("cart from nav", cart);

  useEffect(() => {
    // if (!cart?.cartItems || cart.cartItems.length === 0) {

    // }
    console.log("api called");
    dispatch(getCart());
  }, []);

  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const jwt = localStorage.getItem("jwt");

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = (event) => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpenAuthModal(true);
  };
  const handleClose = () => {
    setOpenAuthModal(false);
  };

  const handleCategoryClick = (category, section, item, close) => {
    navigate(`/${category.id}/${section.id}/${item.id}`);
    close();
  };

  const handleCartClick = () => {
    const cartItemCount = Array.isArray(cart?.cartItems)
      ? cart.cartItems.reduce((total, item) => total + (item?.quantity || 0), 0)
      : 0;

    if (cartItemCount === 0) {
      toast.info("Your cart is empty. Add items to view them here.", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      navigate("/cart");
    }
  };

  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt));
    }
  }, [jwt, auth.jwt,dispatch]);

  useEffect(() => {
    if (auth.user) {
      handleClose();
    }

    // if (location.pathname === "/login" || location.pathname === "/register") {
    //   navigate(-1);
    // }
  }, [auth.user]);

  const handleLogout = () => {
    dispatch(logout());
    handleCloseUserMenu();
    window.location.reload();
  };

  return (
    <div
      className={`shadow-black-500/40 ${
        location.pathname === "/checkout"
          ? "bg-yellow-500"
          : location.pathname === "/account/order"
          ? "bg-yellow-500"
          : location.pathname.startsWith("/profile/user")
          ? "bg-yellow-500"
          : ""
      }`}
    >
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}

                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {navigation.mobilepages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>

                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  <div className="flow-root">
                    {auth.user?.firstName ? (
                      <div className="flex items-center space-x-4">
                        <Avatar
                          className="text-white"
                          onClick={handleUserClick}
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          sx={{
                            bgcolor: deepPurple[500],
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          {auth.user.firstName[0].toUpperCase()}
                        </Avatar>

                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openUserMenu}
                          onClose={handleCloseUserMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem>Profile</MenuItem>
                          <MenuItem onClick={() => navigate("/account/order")}>
                            My Orders
                          </MenuItem>
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                      </div>
                    ) : (
                      <Button
                        onClick={handleOpen}
                        className="text-sm font-medium text-black-700 hover:text-gray-800"
                      >
                        <span className="text-black font-bold">Sign In</span>
                      </Button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative">
        <nav aria-label="Top" className="mx-0 lg:mx-10">
          <div className="flex h-20 items-center px-2 lg:px-20">
            <button
              type="button"
              className="rounded-md font-bold p-2 text-black-900 lg:hidden"
              onClick={() => setOpen(true)}
            >
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-8 w-8" aria-hidden="true" />
            </button>

            {/* Logo */}
            <div className="ml-4 flex lg:ml-0">
              <span className="sr-only">IITIAN_vibes</span>
              <img
                src="https://d22l7bqm0og9id.cloudfront.net/logo.png"
                alt="logo"
                className="h-16 w-30 lg:h-20 lg:w-30 mr-2"
              />
            </div>

            {/* Flyout menus */}

            <div className="ml-auto flex items-center">
              <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch z-10">
                  <div className="flex h-full space-x-8">
                    {navigation.pages.map((page) => (
                      <a
                        key={page.name}
                        href={page.href}
                        className="flex items-center text-sm font-bold text-black-900 hover:underline hover:decoration-2 cursor-pointer"
                      >
                        {page.name}
                      </a>
                    ))}
                  </div>
                </Popover.Group>

                {auth.user?.firstName ? (
                  <div>
                    <Avatar
                      className="text-white"
                      onClick={handleUserClick}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      // onClick={handleUserClick}
                      sx={{
                        bgcolor: "#39311D",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      {auth.user?.firstName[0].toUpperCase()}
                    </Avatar>

                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openUserMenu}
                      onClose={handleCloseUserMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          navigate(`/profile/user/${auth.user?.id}`)
                        }
                      >
                        Profile
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/account/order")}>
                        My Orders
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <Button
                    onClick={handleOpen}
                    className="text-sm font-medium text-black-700 hover:text-gray-800"
                  >
                    <span className="text-black font-bold">SignIn</span>
                  </Button>
                )}
              </div>

              {/* Search */}
              {/* <div className="flex items-center lg:ml-6">
                <p
                  onClick={() => navigate("/products/search")}
                  className="p-2 text-black-400 hover:text-gray-800"
                >
                  <span className="sr-only">Search</span>

                  <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
                </p>
              </div> */}

              {/* Cart */}
              <div className="ml-4 flow-root lg:ml-6">
                <Button
                  className="group -m-2 flex items-center p-2"
                  onClick={handleCartClick}
                >
                  <ShoppingBagIcon
                    className="h-10 w-10 lg:h-10 lg:w-10 flex-shrink-0 text-black-400 group-hover:text-gray-800 text-black"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-lg lg:text-xl font-medium text-black-700 group-hover:text-gray-800 text-black">
                    {Array.isArray(cart?.cartItems)
                      ? cart.cartItems.reduce(
                          (total, item) => total + (item?.quantity || 0),
                          0
                        )
                      : 0}
                  </span>
                  <span className="sr-only">items in cart, view bag</span>
                </Button>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <AuthModel handleClose={handleClose} open={openAuthModal} />
    </div>
  );
}
