import React, { useEffect } from "react";
import OrderCard from "./OrderCard";
import Nav from "../Navigation/Navigation/Nav";
import { useDispatch, useSelector } from "react-redux";
import { getOrderHistory } from "../../../State/Order/Action";
import Loader from "../Loader/Loader";

const Order = () => {
  const { order } = useSelector((store) => store);
  const dispatch = useDispatch();

  // console.log("loading value: ", order.loading);
  console.log("order from order jsx", order);

  useEffect(() => {
    dispatch(getOrderHistory());
  }, [dispatch]);

  return (
    <>
      {order.loading ? (
        <Loader />
      ) : (
        <div className="min-h-screen flex flex-col">
          <Nav />
          <div className="flex-1 px-4 mt-10 mb-24 sm:px-6 lg:px-20">
            <div className="flex flex-col space-y-6">
              {order.orders?.map((item) => (
                <div
                  key={item.id}
                  className="w-full border border-gray-300 rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-300"
                >
                  {/* Order ID as header */}
                  <h2 className="text-lg font-semibold text-gray-700 mb-3">
                    Order ID: {item.id}
                  </h2>
                  <hr className="mb-4" />
                  {/* Order details */}
                  <OrderCard item={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Order;
