// CartItem.jsx
import { Button, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { removeCartItem, updateCartItem } from "../../../State/Cart/Action";
import { getUser } from "../../../State/Auth/Action";

function CartItem({ item, isReadOnly }) {
  const dispatch = useDispatch();
  const { auth } = useSelector((store) => store);
  const jwt = localStorage.getItem("jwt");

  const handleUpdateCartItem = (num) => {
    const updatedCartItem = {
      product: item?.product,
      size: item?.size,
      quantity: item?.quantity + num,
      price: item?.price,
      discountedPrice: item?.discountedPrice,
    };
    const data = {
      data: updatedCartItem,
      userId: auth.user?.id,
      cartItemId: item?.userId ? item?.userId : item?.id,
    };
    dispatch(updateCartItem(data));
  };

  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt));
    }
  }, [jwt, auth.jwt, dispatch]);

  const handleRemoveCartItem = () => {
    dispatch(removeCartItem(item?.id));
  };

  return (
    <div className="p-5 shadow-lg border rounded-md">
      <div className="flex items-center">
        <div className="w-[5rem] h-[5rem] lg:w-[9rem] lg:h-[9rem]">
          <img
            className="w-full h-full object-cover object-top"
            src={item?.product?.imageUrl}
            alt={`item${item?.id}`}
          />
        </div>
        <div className="ml-5 space-y-1">
          <p className="font-semibold">{item?.product?.title}</p>
          <p className="opacity-70">
            Size: {item?.size}, {item?.product?.color}
          </p>
          <p className="opacity-70 mt-2">{item?.product?.brand}</p>
          <div className="flex space-x-5 items-center text-gray-900 pt-6">
            <p className="font-semibold">
              Rs.{item?.product?.discountedPrice}
            </p>
            <p className="opacity-50 line-through">Rs.{item?.product?.price}</p>
            <p className="text-green-600 font-semibold">25% off</p>
          </div>
        </div>
      </div>

      {/* Conditionally render the update and remove buttons */}
      {!isReadOnly && (
        <div className="lg:flex items-center lg:space-x-10 pt-4">
          <div className="flex items-center space-x-2">
            <IconButton
              onClick={() => handleUpdateCartItem(-1)}
              disabled={item?.quantity <= 1}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <span className="py-1 px-7 border rounded-sm">
              {item?.quantity}
            </span>
            <IconButton
              onClick={() => handleUpdateCartItem(1)}
              sx={{ color: "RGB(145 85 253)" }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
          <div>
            <Button
              onClick={handleRemoveCartItem}
              sx={{ color: "RGB(145 85 253)" }}
            >
              Remove
            </Button>
          </div>
        </div>
      )}

      {/* If read-only, display the quantity without update buttons */}
      {isReadOnly && (
        <div className="pt-4">
          <p className="font-semibold">
            Quantity: <span>{item?.quantity}</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default CartItem;
