import React from "react";
import { Grid, TextField, Button } from "@mui/material"; // Use MUI's Button
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../State/Auth/Action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const data = new FormData(event.currentTarget);
  
    const userData = {
      email: data.get("email"),
      password: data.get("password"),
    };
  
    dispatch(login(userData));
  
    console.log("userData ", userData);
  
    // Wait for 3 seconds and then reload the page
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  return (
    <div className="m-10 lg:m-auto">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="email"
              sx={{
                "& .MuiInputLabel-root": {
                  textDecoration: "none",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              autoComplete="current-password"
              sx={{
                "& .MuiInputLabel-root": {
                  textDecoration: "none",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              size="large"
              sx={{
                backgroundColor: "#f1c32f",
                color: "black",
                fontWeight: "bold",
                fontFamily: "Anton, sans-serif",
                py: 1.5,
                "&:hover": {
                  backgroundColor: "#f1f13e",
                },
              }}
            >
              LOGIN
            </Button>
          </Grid>
        </Grid>
      </form>
      <div className="flex justify-center flex-col items-center">
        <div className="py-3 flex items-center">
          <p>If you don't have an account?</p>
          <Button
            onClick={() => navigate("/register")}
            variant="text"
            color="secondary"
            sx={{
              ml: 2,
              textDecoration: "underline",
            }}
          >
            Register
          </Button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginForm;
