export const CREATE_ORDER_REQUEST = "CREATE ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE ORDER SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE ORDER FAILURE";

export const GET_ORDER_BY_ID_REQUEST = "GET_ORDER_BY_ID_REQUEST";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";
export const GET_ORDER_BY_ID_FAILURE = "GET_ORDER_BY_ID_FAILURE";

export const GET_ORDER_HISTORY_REQUEST = "GET_ORDER_HISTORY_REQUEST";
export const GET_ORDER_HISTORY_SUCCESS = "GET_ORDER_HISTORY_SUCCESS";
export const GET_ORDER_HISTORY_FAILURE = "GET_ORDER_HISTORY_FAILURE";

export const REMOVE_ORDER_ITEM_REQUEST = "REMOVE_ORDER_ITEM_REQUEST";
export const REMOVE_ORDER_ITEM_SUCCESS = "REMOVE_ORDER_ITEM_SUCCESS";
export const REMOVE_ORDER_ITEM_FAILURE = "REMOVE_ORDER_ITEM_FAILURE";

export const UPDATE_ORDER_ITEM_REQUEST = "UPDATE_ORDER_ITEM_REQUEST";
export const UPDATE_ORDER_ITEM_SUCCESS = "UPDATE_ORDER_ITEM_SUCCESS";
export const UPDATE_ORDER_ITEM_FAILURE = "UPDATE_ORDER_ITEM_FAILURE";
