import React from 'react'
import AddressCard from '../AddressCard/AddressCard';
import OrderTracker from './OrderTracker';
import {Grid} from "@mui/material";
import { deepPurple } from '@mui/material/colors';
import {Box} from '@mui/material';
import { StarBorder } from '@mui/icons-material';

const OrderDeatils = () => {
  return (
    <div className='px-5 lg:px-20'>
        <div>
            <h1 className='font-bold text-xl py-7'> Delivery Address</h1>
            <AddressCard/>
        </div>
        <div className='py-20'>
            <OrderTracker activeStep={3}/>
        </div>
        <Grid className='space-x-5' container>
            {[1,1,1,1,1,1].map((item) => {
                <Grid item container className='shadow-xl rounded-md p-5 border' sx={{alignItems:"center", justifyContent:'space-between'}}>
                <Grid item xs={6}>
                    <div className='flex items-center space-x-4'>
                        <img className='w-[5rem] h=[5rem] object-cover object-top' src='' alt=''/>
                        <div className='space-y-2 ml-5'>
                            <p className='font-semibold'> </p>
                            <p className='space-x-5 opacity-50 text-xs font-semibold'><span></span></p>
                            <p></p>
                        </div>
                    </div>
                </Grid>
                <Grid item>
                    <Box sx={{color:deepPurple[500]}}>
                        <StarBorder sx={{fontSize:"2rem"}} className='px-2 text-5xl'/>
                        <span>Rate & review product</span>
                    </Box>
                </Grid>
            </Grid>
            })}
        </Grid>
    </div>
  )
}

export default OrderDeatils