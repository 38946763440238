import React, { useState, Fragment } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { navigation } from "../Navigation/Navigation/navigation"; // Adjust the import path accordingly

const NoRefundPolicy = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Mobile Menu */}
      <Transition.Root show={openMenu} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpenMenu}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpenMenu(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Navigation Links */}
                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {navigation.mobilepages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Header Section */}
      <header className="bg-yellow-500 w-full h-16 lg:h-24 mb-8 flex items-center justify-between px-4 lg:px-14 shadow-md">
        {/* Hamburger Menu on Mobile */}
        <div className="flex lg:hidden">
          <button
            onClick={() => setOpenMenu(true)}
            className="text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
        </div>

        {/* Back Arrow on Desktop */}
        <div className="hidden lg:flex">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: { xs: 30, sm: 40, md: 50, lg: 60 } }}
              className="text-black mr-2"
            />
            <span className="sr-only">Back</span>
          </button>
        </div>

        {/* Page Title */}
        <span className="text-black font-extrabold text-2xl sm:text-3xl lg:text-5xl tracking-wider absolute left-1/2 transform -translate-x-1/2">
          NO REFUND POLICY
        </span>

        {/* Placeholder for Alignment */}
        <div className="hidden lg:block" style={{ width: "24px" }}></div>
      </header>

      {/* Content Section */}
      <main className="flex-1 bg-gray-100 px-6 lg:px-16 py-4 space-y-6 text-gray-800">
        {/* ORDERS & PAYMENT Section */}
        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-lg">
          <h2 className="font-bold text-xl lg:text-2xl">ORDERS & PAYMENT</h2>
        </div>

        {/* FAQ Items */}
        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">What should I do if my payment fails?</p>
          <p>
            In case there is a failure in payment, please retry and keep the following things in mind:
            Confirm if the information you’ve provided is correct and that your internet connection wasn’t disrupted.
            If your account has been debited after a payment failure, it is normally rolled back to your bank account within 10 business days.
            For further assistance, email us at <a href="mailto:connect.iitianvibes@gmail.com" className="text-blue-700 hover:underline">connect.iitianvibes@gmail.com</a>.
          </p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">How do I cancel the order I have placed?</p>
          <p>
            There is no cancellation process. Once an order is placed, it cannot be cancelled.
          </p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">
            I want to place an order but I don’t want any price tag or invoice attached as it is a gift for someone. Is it possible?
          </p>
          <p>
            We allow for removal or blacking out of the price or invoice as a gift.
          </p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">Can I get my order delivered faster?</p>
          <p>
            Sorry, currently we do not have any service available to expedite the order delivery.
          </p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">
            I wish to add a few more products to my order. Will it be possible?
          </p>
          <p>
            Once you have confirmed the order and we have accepted it, you cannot add any more products to your order.
            You will have to place a fresh order for the other products.
          </p>
        </div>

        {/* SHIPPING & TRACKING Section */}
        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold">
          <h2 className="font-bold text-xl lg:text-2xl">SHIPPING & TRACKING</h2>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">
            Can I modify the shipping address of my order before it has been shipped?
          </p>
          <p>
            Sorry, that is not possible at the moment as the system would have already passed the mobile number and address to our warehouse to pack and ship your product.
          </p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">
            How long will it take for my order to be delivered?
          </p>
          <p>
            Orders once shipped are typically delivered in 1-4 business days or 4-7 business days.
            Delivery time may vary depending upon the product and other factors (public holidays, extreme weather conditions, etc.).
          </p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">
            Are there any additional shipping charges?
          </p>
          <p>The IITIAN Vibes provides FREE shipping for all orders.</p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">What if my order is undelivered?</p>
          <p>
            For prepaid orders, if our courier partners are unable to deliver the product and they send it back to us,
            we will initiate a refund as TSS Money to your The Souled Store account, which will reflect within 24-48 hours of initiation.
          </p>
        </div>

        {/* MISCELLANEOUS Section */}
        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold">
          <h2 className="font-bold text-xl lg:text-2xl">MISCELLANEOUS</h2>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">
            The product I want to return was bought on discount. Will I get refunded the full amount?
          </p>
          <p>
            No. You will only receive the exact amount paid for the product.
          </p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">Can I try the product before buying?</p>
          <p>
            We only sell online so there is no try-and-buy option available. However, to make sure you pick the right size,
            we have a size chart with measurements on our product pages.
          </p>
        </div>

        {/* RETURNS, EXCHANGE & REFUND Section */}
        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <h2 className="font-bold text-xl lg:text-2xl">RETURNS, EXCHANGE & REFUND</h2>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">
            The product I want to return was bought on discount. Will I get refunded the full amount?
          </p>
          <p>
            No. You will only receive the exact amount paid for the product.
          </p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">
            What are the terms of the exchange policy?
          </p>
          <p>There is no exchange policy.</p>
        </div>

        <div className="bg-gray-200 p-4 rounded-md text-black font-semibold text-sm lg:text-lg">
          <p className="underline">What are the terms of the refund policy?</p>
          <p>There is no refund policy.</p>
        </div>
      </main>
    </div>
  );
};

export default NoRefundPolicy;
