import React from "react";
import Nav from "../Navigation/Navigation/Nav";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
   
    <div className="relative h-screen w-screen">
      <div className="img-text-wrapper">
        <div className="logo-wrapper">
        <img
            src="https://d22l7bqm0og9id.cloudfront.net/newLandingPage1.png"
            alt="landing page background"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0 flex flex-col justify-center items-center"></div>
        </div>
        <Nav />
        <button
          className="absolute bottom-[22rem] font-[teko] left-3 lg:bottom-44 lg:left-40 px-2 py-2 lg:px-7 lg:py-4 bg-black text-white font-semibold shadow-lg hover:p-3 lg:hover:p-7 text-md lg:text-3xl transition-all duration-300 rounded-lg"
          onClick={() => navigate("/collection")}
        >
          NEW COLLECTION
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
