import React, { useEffect } from "react";
import AddressCard from "../AddressCard/AddressCard";
import { Button } from "@mui/material";
import CartItem from "../Cart.jsx/CartItem"; // Fixed import path
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderById } from "../../../State/Order/Action";
import { createPayment } from "../../../State/Payment/Action";
import Loader from "../Loader/Loader";

function OrderSummary() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");
  const { order } = useSelector((store) => store);
  const { cart } = useSelector((store) => store.cart);


  useEffect(() => {
    if (orderId) {
      dispatch(getOrderById(orderId));
    }
  }, [orderId, dispatch]);
  useEffect(() => {
    if (orderId) {
      dispatch(getOrderById(orderId));
    }
  }, [cart, orderId, dispatch]);
  const handleCheckout = () => {
    console.log("Payment link request initiated");
    dispatch(createPayment(orderId));
  };

  return (
    <>
      {order.loading ? (
        <Loader />
      ) : (
        <div className="p-4 md:p-5">
          <div className="lg:grid lg:grid-cols-4 gap-6">
            {/* Left Column: Address and Products */}
            <div className="col-span-3 space-y-6">
              {/* Address Card */}
              <div className="shadow-lg rounded-md border p-4 sm:p-5 bg-white">
                <AddressCard address={order.order?.shippingAddress} />
              </div>

              {/* Products List */}
              <div className="space-y-4">
                {order.order?.orderItems.map((item, index) => (
                  <CartItem key={index} item={item} isReadOnly={true} />
                ))}
              </div>
            </div>

            {/* Right Column: Price Details */}
            <div className="col-span-1 mt-6 lg:mt-0">
              <div className="lg:sticky top-5 lg:h-[100vh]">
                <div className="border rounded-md p-5 sm:p-6 shadow-lg bg-white">
                  <p className="uppercase text-center text-xl sm:text-2xl font-bold pb-4 sm:pb-6 text-gray-800">
                    Price Details
                  </p>
                  <hr className="my-3 sm:my-4" />
                  <div className="space-y-3 sm:space-y-4 font-semibold text-gray-700 mb-8 sm:mb-10 mt-4">
                    <div className="flex justify-between items-center pt-2 sm:pt-3 text-base sm:text-lg">
                      <span>Price</span>
                      <span className="text-gray-800">
                        ₹ {order.order?.totalPrice.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between items-center pt-2 sm:pt-3 text-base sm:text-lg">
                      <span>Discount</span>
                      <span className="text-green-600">
                        -₹{" "}
                        {(
                          order.order?.totalPrice -
                          order.order?.totalDiscountedPrice
                        ).toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between items-center pt-2 sm:pt-3 text-base sm:text-lg">
                      <span>Delivery Charge</span>
                      <span className="text-green-600">Free</span>
                    </div>
                    <div className="flex justify-between items-center pt-3 sm:pt-4 font-bold text-lg border-t border-gray-300 mt-4 sm:mt-5 pt-4">
                      <span>Total Price</span>
                      <span className="text-green-600">
                        ₹ {order.order?.totalDiscountedPrice.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    className="w-full mt-5 rounded-lg shadow-md hover:shadow-lg transition duration-200"
                    sx={{
                      px: "2.5rem",
                      py: "0.8rem",
                      bgcolor: "#9155fd",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      "&:hover": {
                        bgcolor: "#7a3ed1",
                      },
                    }}
                    onClick={handleCheckout}
                  >
                    PAYMENT
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderSummary;
