export const ADD_ITEM_TO_CART_REQUEST="ADD_ITEM_TO_CART_REQUEST"
export const ADD_ITEM_TO_CART_SUCCESS="ADD_ITEM_TO_CART_SUCCESS"
export const ADD_ITEM_TO_CART_FAILURE="ADD_ITEM_TO_CART_FAILURE"

export const GET_CART_REQUEST="GET_CART_REQUEST"
export const GET_CART_SUCCESS='GET_CART_SUCCESS'
export const GET_CART_FAILURE="GET_CART_FAILURE"

export const REMOVE_CART_ITEM_REQUEST="REMOVE_CART_ITEM_REQUEST";
export const REMOVE_CART_ITEM_SUCCESS="REMOVE_CART_ITEM_SUCCESS";
export const REMOVE_CART_ITEM_FAILURE="REMOVE_CART_ITEM_FAILURE";

export const UPDATE_CART_ITEM_REQUEST="UPDATE_CART_ITEM_REQUEST";
export const UPDATE_CART_ITEM_SUCCESS="UPDATE_CART_ITEM_SUCCESS";
export const UPDATE_CART_ITEM_FAILURE="UPDATE_CART_ITEM_FAILURE";