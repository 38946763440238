import React, { useEffect } from "react";
import { getUser } from "../../../State/Auth/Action";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Navigation/Navigation/Nav";

const Profile = () => {
  const jwt = localStorage.getItem("jwt");
  const dispatch = useDispatch();
  const { auth } = useSelector((store) => store);

  useEffect(() => {
    dispatch(getUser(jwt));
  }, [jwt]);

  return (
    <div className="min-h-screen bg-gray-100">
      <Nav />
      <div className="grid grid-cols-1 gap-4 p-4">
        {/* Personal Information Section */}
        <div className="p-4 bg-gray-200 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Personal Information</h2>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              value={auth.user?.firstName}
              disabled
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-50 text-gray-600 cursor-not-allowed"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              value={auth.user?.email}
              disabled
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-50 text-gray-600 cursor-not-allowed"
            />
          </div>
        </div>

        {/* Contact Information Section */}
        <div className="p-4 bg-gray-200 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Contact Information</h2>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Phone
            </label>
            <input
              type="tel"
              value={auth.user?.mobile}
              disabled
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-50 text-gray-600 cursor-not-allowed"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Address
            </label>
            <input
              type="text"
              value={auth.user?.address[0]?.streetAddress || ""}
              disabled
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-50 text-gray-600 cursor-not-allowed"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              City
            </label>
            <input
              type="text"
              value={auth.user?.address[0]?.city || ""}
              disabled
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-50 text-gray-600 cursor-not-allowed"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              State
            </label>
            <input
              type="text"
              value={auth.user?.address[0]?.state|| ""}
              disabled
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-50 text-gray-600 cursor-not-allowed"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              ZipCode
            </label>
            <input
              type="text"
              value={auth.user?.address[0]?.zipCode || ""}
              disabled
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-50 text-gray-600 cursor-not-allowed"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
