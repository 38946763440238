import axios from "axios";
import {
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  GET_ORDER_BY_ID_FAILURE,
  GET_ORDER_BY_ID_REQUEST,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_HISTORY_FAILURE,
  GET_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_SUCCESS,
  REMOVE_ORDER_ITEM_REQUEST,
  REMOVE_ORDER_ITEM_SUCCESS,
  REMOVE_ORDER_ITEM_FAILURE,
  UPDATE_ORDER_ITEM_REQUEST,
  UPDATE_ORDER_ITEM_SUCCESS,
  UPDATE_ORDER_ITEM_FAILURE,
} from "./ActionType";

import { api, API_BASE_URL } from "../../config/apiConfig";

export const removeOrderItem = (orderItemId) => async (dispatch) => {
  dispatch({ type: REMOVE_ORDER_ITEM_REQUEST });

  try {
    await api.delete(`/api/orders/removeItem/${orderItemId}`);
    dispatch({ type: REMOVE_ORDER_ITEM_SUCCESS, payload: orderItemId });
  } catch (error) {
    dispatch({ type: REMOVE_ORDER_ITEM_FAILURE, payload: error.message });
  }
};

export const updateOrderItem = (orderItemId, updatedData) => async (dispatch) => {
  dispatch({ type: UPDATE_ORDER_ITEM_REQUEST });

  try {
    const response = await api.put(`/api/orders/updateItem/${orderItemId}`, updatedData);
    dispatch({ type: UPDATE_ORDER_ITEM_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_ORDER_ITEM_FAILURE, payload: error.message });
  }
};

export const createOrder = (reqData) => async (dispatch) => {
  console.log("req data", reqData);

  try {
    dispatch({ type: CREATE_ORDER_REQUEST });

    // const config = {
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${reqData.jwt}`,
    //     },
    // };

    const { data } = await api.post(
      `${API_BASE_URL}/api/orders/`,
      reqData.address
    );

    console.log("data from order", data);

    if (data.id) {
      reqData.navigate({ search: `step=3&order_id=${data.id}` });
    }
    console.log("created order - ", data);
    dispatch({
      type: CREATE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log("catch error : ", error);
    dispatch({
      type: CREATE_ORDER_FAILURE,
      payload: error.message,
    });
  }
};

export const getOrderById = (orderId) => async (dispatch) => {
  console.log("get order req ", orderId);
  try {
    dispatch({ type: GET_ORDER_BY_ID_REQUEST });

    const { data } = await api.get(`/api/orders/${orderId}`);

    console.log("order by id", data);
    dispatch({
      type: GET_ORDER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log("catch", error);
    dispatch({
      type: GET_ORDER_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const getOrderHistory = () => async(dispatch) => {
  try{

    dispatch({type:GET_ORDER_HISTORY_REQUEST});
    const { data } = await api.get(`/api/orders/user`);

    console.log("orders history", data);
    dispatch({
      type: GET_ORDER_HISTORY_SUCCESS,
      payload: data,
    });

  }catch(error){
    console.log("catch", error);
    dispatch({
      type: GET_ORDER_HISTORY_FAILURE,
      payload: error.message,
    });
  }

}
