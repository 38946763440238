import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faInstagram,
  faLinkedin,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 text-white py-8 mb-0">
      <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 gap-8 mb-0">
      
        <div className="footer-column">
          <h3 className="font-bold mb-4">Connect with Us</h3>
          <a href="/contactUs" className="block text-gray-300 hover:text-white">
            Contact Us
          </a>
          <a href="/aboutUs" className="block text-gray-300 hover:text-white">
            About Us
          </a>
        </div>

        <div className="footer-column">
          <h3 className="font-bold mb-4">Legal</h3>
          <a
            href="/terms&conditions"
            className="block text-gray-300 hover:text-white"
          >
            Terms and Conditions
          </a>
          <a href="/noRefund" className="block text-gray-300 hover:text-white">
            No Refund Policy
          </a>
          <a
            href="/privacyPolicy"
            className="block text-gray-300 hover:text-white"
          >
            Privacy Policy
          </a>
        </div>

        <div className="footer-column">
          <h3 className="font-bold mb-4">Follow Us</h3>
          <div className="flex space-x-4">
            <a href="https://wa.me/8233014848" className="hover:text-white">
              <FontAwesomeIcon icon={faWhatsapp} size="2x" />
            </a>
            <a
              href="https://www.instagram.com/iitian.vibess/"
              className="hover:text-white"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a
              href="https://www.linkedin.com/in/iitian-vibes-400093337/"
              className="hover:text-white"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-600 mt-8">
        <div className="container mx-auto px-4 text-center py-4">
          <p>&copy; {currentYear} IITIAN_VIBES. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
