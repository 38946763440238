import React from 'react'

const Loader = () => {
  return (
    <div class="flex items-center justify-center min-h-screen bg-gray-100">
  <div class="relative w-24 h-24">
 
    <div class="absolute inset-0 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin-slow"></div>
    

    <div class="absolute inset-3 border-4 border-yellow-500 border-t-transparent rounded-full animate-spin-slow animation-delay-200"></div>


    <div class="absolute inset-8 bg-yellow-500 rounded-full animate-pulse-fast"></div>
  </div>
</div>
  );

}

export default Loader