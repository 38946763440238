import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { getOrderById } from '../../../State/Order/Action';
import { Alert, AlertTitle } from '@mui/material';
import { updatePayment } from '../../../State/Payment/Action';
import OrderTracker from "../../components/Order/OrderTracker";
import {Grid } from "@mui/material";
import AddressCard from '../AddressCard/AddressCard';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const [paymentId, setPaymentId] = useState();
    const [referenceId, setReferenceId] = useState();
    const [paymentStatus, setPaymentStatus] = useState();
    const {orderId} = useParams();
    const navigate = useNavigate();

    console.log(orderId);
    const dispatch = useDispatch();
    const {order} = useSelector(store=>store);

    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);

        setPaymentId(urlParams.get("razorpay_payment_id"));
        setPaymentStatus(urlParams.get("razorpay_payment_link_status"))
    },[])

    useEffect(() => {
      const data = {orderId, paymentId}
      dispatch(getOrderById(orderId));
      dispatch(updatePayment(data));
    },[orderId,paymentId]);
  return (
    <div className='px-2 lg:px-36'>
      <div className='flex flex-col justify-center items-center'>
        <Alert
        variant = 'filled'
        severity='success'
        sx={{mb:6, width:"fit-content"}}
        >
          <AlertTitle>Payment Success</AlertTitle>
          Congratulations Your Order Got Placed
        </Alert>
      </div>
      <OrderTracker activeStep={1}/>
      <Grid container className='space-y-5 py-5 pt-20'>
        {order.order?.orderItems.map((item)=> <Grid container item className='shadow-xl rounded-md p-5' sx={{alignItems: "center", justifyContent:"space-between"}}>
          <Grid item xs={6}>
            <div className='flex items-center'>
              <img className='w-[5rem] h-[5rem] object-cover object-top' src={item.product.imageUrl} alt=''/>
              <div className='ml-5 space-y-2'>
                <p>{item.product.title}</p>
                <div className='opacity-50 text-xs font-semi'>
                  <span>Color: {item.color}</span>
                  <span>Size: {item.size}</span>
                </div>
                <p>Seller: {item.product.brand}</p>
                <p>{item.price}</p>
              </div>
            </div>
          </Grid>

          <Grid item>
            <AddressCard address={order.order?.shippingAddress}/>
          </Grid>

        </Grid>)}
        <Button variant="contained"color="secondary" onClick={()=>navigate("/collection")}>Continue Vibing</Button>
      </Grid>
    </div>
  )
}

export default PaymentSuccess