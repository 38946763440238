import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { navigation } from "../Navigation/Navigation/navigation"; // Adjust the import path accordingly

const About = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div>
      {/* Mobile menu */}
      <Transition.Root show={openMenu} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setOpenMenu}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpenMenu(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {navigation.mobilepages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Header section */}
      <div className="bg-yellow-500 w-full h-20 lg:h-32 mb-12 flex items-center justify-between px-4 lg:px-14 shadow-md">
        {/* Hamburger menu on mobile */}
        <div className="flex lg:hidden">
          <button
            onClick={() => setOpenMenu(true)}
            className="text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
        </div>

        {/* Back arrow on desktop */}
        <div className="hidden lg:flex">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: { xs: 30, sm: 40, md: 50, lg: 60 } }}
              className="text-black mr-2"
            />
            <span className="sr-only">Back</span>
          </button>
        </div>

        {/* Page Title */}
        <span className="text-black font-extrabold text-3xl lg:text-5xl tracking-wider">
          ABOUT US
        </span>

        {/* Placeholder for alignment */}
        <div className="flex lg:hidden" style={{ width: "24px" }}></div>
        <div className="hidden lg:block" style={{ width: "24px" }}></div>
      </div>
      {/* Content section */}
      <div className="bg-white px-8 lg:px-20 py-10 space-y-10 text-gray-800">
        {/* About IITIAN Vibes */}
        <div className="bg-gray-100 p-8 rounded-lg shadow-lg transition duration-300 transform hover:scale-105">
          <h2 className="text-xl lg:text-3xl font-bold text-[#76520E] mb-4 border-b-2 border-[#76520E] pb-2">
            The Name: IITIAN Vibes
          </h2>
          <p className="text-md lg:text-lg leading-relaxed mb-4">
            The name "IITIAN Vibes" reflects the spirit and culture associated
            with students from the Indian Institutes of Technology (IITs).
            Here’s why it resonates:
          </p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li className="text-md lg:text-lg">
              <strong className="text-[#76520E]">
                Pride in the IIT Identity:
              </strong>{" "}
              IITians are celebrated for their rigorous academic backgrounds and
              high achievements. "IITIAN Vibes" captures pride, connection, and
              a sense of belonging to this prestigious community.
            </li>
            <li className="text-md lg:text-lg">
              <strong className="text-[#76520E]">
                Distinctive Style and Mindset:
              </strong>{" "}
              Known for their innovative and bold thinking, IITians are
              trendsetters. "Vibes" suggests a unique energy and mindset that
              resonates with this group.
            </li>
            <li className="text-md lg:text-lg">
              <strong className="text-[#76520E]">
                Youthful, Streetwear Appeal:
              </strong>{" "}
              Adding "Vibes" to the name gives it a modern feel, appealing to a
              younger audience connected with streetwear culture and expressive
              fashion.
            </li>
          </ul>
          <p className="mt-6 text-md lg:text-lg">
            "IITIAN Vibes" represents the intellectual strength of IITians
            combined with the bold style of streetwear, crafted to express this
            unique identity.
          </p>
        </div>

        {/* About The Team */}
        <div className="bg-gray-100 p-8 rounded-lg shadow-lg transition duration-300 transform hover:scale-105">
          <h2 className="text-xl lg:text-3xl font-bold text-[#76520E] mb-4 border-b-2 border-[#76520E] pb-2">
            The Team Behind IITIAN Vibes
          </h2>
          <p className="text-md lg:text-lg leading-relaxed mb-4">
            The team behind "IITIAN Vibes" is led by{" "}
            <span className="font-semibold text-[#76520E]">
              Anurag Kumar Bharti
            </span>
            ,{" "}
            <span className="font-semibold text-[#76520E]">Arun Raghav S</span>,
            and <span className="font-semibold text-[#76520E]">Arpit Arya</span>{" "}
            – a dynamic trio with a shared vision to blend the intellectual
            spirit of IITians with bold, expressive fashion.
          </p>
          <p className="text-md lg:text-lg leading-relaxed">
            Together, they bring creativity, innovation, and a deep
            understanding of their community, crafting designs that resonate
            with the unique identity and aspirations of IITians. Through "IITIAN
            Vibes," they aim to redefine streetwear for the ambitious youth,
            reflecting the mindset and style of those who dare to lead and make
            an impact.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
