import {api} from "../../config/apiConfig";
import {FIND_PRODUCTS_SUCCESS, FIND_PRODUCTS_FAILURE,FIND_PRODUCTS_REQUEST,FIND_PRODUCT_BY_ID_FAILURE,FIND_PRODUCT_BY_ID_REQUEST,FIND_PRODUCT_BY_ID_SUCCESS, GET_ALL_PRODUCTS_REQUEST
} from "./ActionType";
import axios from "axios";

export const findProducts = (reqData) => async (dispatch) => {
  dispatch({type:FIND_PRODUCTS_REQUEST});
  const {
    category,
  } = reqData;
  try {
    const {data} = await api.get(
        `/api/products?category=${category}`
    )

    console.log("data : ",data);
    dispatch({type:FIND_PRODUCTS_SUCCESS, payload : data})
    
  } catch (error) {
    dispatch({type:FIND_PRODUCTS_FAILURE, paload:error.message})
  }
};

export const findProductsById = (reqData) => async (dispatch) => {
    dispatch({type:FIND_PRODUCT_BY_ID_REQUEST})
    const {
     productId
    } = reqData;
    try {
      const {data} = api.get(
          `/api/products/id/${productId}`
      )
      dispatch({type:FIND_PRODUCTS_SUCCESS, payload : data})
  
    } catch (error) {
      dispatch({type:FIND_PRODUCTS_FAILURE, paload:error.message})
    }
  };


  