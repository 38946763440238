export const FIND_PRODUCT_BY_ID_REQUEST = "FIND_PRODUCT_BY_ID_REQUEST";
export const FIND_PRODUCT_BY_ID_SUCCESS = "FIND_PRODUCT_BY_ID_SUCCESS";
export const FIND_PRODUCT_BY_ID_FAILURE = "FIND_PRODUCT_BY_ID_FAILURE";

export const FIND_PRODUCTS_REQUEST = "FIND_PRODUCT_BY_CATEGORY_REQUEST";
export const FIND_PRODUCTS_SUCCESS = "FIND_PRODUCT_BY_CATEGORY_SUCCESS";
export const FIND_PRODUCTS_FAILURE = "FIND_PRODUCT_BY_CATEGORY_FAILURE";

// export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
// export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
// export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";
