import React, { useState, useEffect } from "react";
import Nav from "../../components/Navigation/Navigation/Nav";
import { Link } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ProductCard from "../../components/Product/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { findProducts } from "../../../State/Product/Action";
import Loader from "../../components/Loader/Loader";

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  768: { items: 1 }, // Ensure one item on tablet
  1024: { items: 1 }, // One item on larger screens
};

const HomePage = () => {
  const [activeCollection, setActiveCollection] = useState("Hoodies");

  const dispatch = useDispatch();
  const { product } = useSelector((store) => store);
  let sortedProducts;

  const Products = [...(product?.products || [])]; // Clone the array to avoid mutation
  // console.log("active selection ", activeCollection);
  // console.log("sorted products", sortedProducts);
  if (activeCollection === "Hoodies") {
    sortedProducts = Products.sort((a, b) => b.id - a.id); // Sort in descending order by id or any other attribute
  } else {
    sortedProducts = Products;
  }

  const items = sortedProducts.map((productItem) => (
    <ProductCard key={productItem.id} product={productItem} />
  ));

  useEffect(() => {
    const data = { category: activeCollection };
    dispatch(findProducts(data));
  }, [activeCollection, dispatch]);

  return (
    <div className="min-h-screen">
      <div className="relative h-screen w-screen mb-72 lg:mb-20">
        <div className="img-text-wrapper relative h-full w-full">
          <img
            src={
              window.innerWidth < 768
                ? "https://d22l7bqm0og9id.cloudfront.net/mobilehomepage1.png"
                : "https://d22l7bqm0og9id.cloudfront.net/background.jpg"
            }
            alt="landing page"
            className="absolute top-0 left-0 w-full h-full object-cover lg:blur-none md:blur-none blur-md "
          />

          <div className="absolute inset-0 flex flex-col justify-between">
            {/* Navbar with Shadow */}
            <Nav />

            {/* Grid with links below the navbar */}
            <div className="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2 md:gap-4 py-2 md:py-4 lg:py-6">
              <Link
                to="/collection"
                className="bg-[#d6ae1b] border-2 mx-1 lg:mx-0 lg:ml-16 border-b-8 border-black py-3 md:py-4 lg:py-6 text-center rounded-lg shadow hover:bg-gray-300 transition"
                onClick={() => setActiveCollection("T-shirts")}
              >
                <h2 className="text-md md:text-xl lg:text-3xl font-bold font-kaushan">
                  T-Shirts
                </h2>
              </Link>
              <Link
                to="/collection"
                className="bg-[#d6ae1b] border-2 mx-1 lg:mx-0 lg:mr-16 border-b-8 border-black py-3 md:py-4 lg:py-6 text-center rounded-lg shadow hover:bg-gray-300 transition"
                onClick={() => setActiveCollection("Hoodies")}
              >
                <h2 className="text-md md:text-xl lg:text-3xl font-bold font-kaushan">
                  Hoodies
                </h2>
              </Link>
            </div>

            {/* Carousel wrapped inside the image */}
            {product.loading ? (
              <Loader />
            ) : (
              <div className="mb-10 mx-2 md:mx-4 lg:mx-0">
                <AliceCarousel
                  mouseTracking
                  items={items}
                  responsive={responsive}
                  controlsStrategy="alternate"
                  renderPrevButton={() => (
                    <button className="absolute left-6 md:left-20 lg:top-1/2 top-1/4 transform -translate-y-1/2 bg-white text-black p-3 rounded-lg shadow-lg hover:bg-gray-800 hover:text-white transition">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>
                  )}
                  renderNextButton={() => (
                    <button className="absolute right-6 md:right-20 lg:top-1/2 top-1/4 transform -translate-y-1/2 bg-white text-black p-3 rounded-lg shadow-lg hover:bg-gray-800 hover:text-white transition">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  )}
                  disableButtonsControls={items.length <= 1} // Hide buttons if only one item
                  disableDotsControls={items.length <= 1} // Hide dots if only one item
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
