export const REGISTER_REQUEST='REGISTER_REQUEST';
export const REGISTER_SUCCESS='REGISTER_SUCCESS';
export const REGISTER_FAILURE='REGISTER_FAILURE';

export const LOGIN_REQUEST='LOGIN_REQUEST';
export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const LOGIN_FAILURE='LOGIN_FAILURE';

export const GET_USER_REQUEST='GET_USER_REQUEST';
export const GET_USER_SUCCESS='GET_USER_SUCCESS';
export const GET_USER_FAILURE='GET_USER_FAILURE';

export const LOGOUT="LOGOUT";