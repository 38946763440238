// reducer.js

import {
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
} from "./ActionType";

const initialState = {
  loading: false,
  paymentStatus: null,
  refundStatus: null,
  error: null,
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PAYMENT_REQUEST:
    case UPDATE_PAYMENT_REQUEST:
      return { ...state, loading: true, error: null };

    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentStatus: action.payload,
        error: null,
      };

    case CREATE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        paymentStatus: null,
        error: action.payload,
      };

    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        refundStatus: action.payload,
        error: null,
      };

    case UPDATE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        refundStatus: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
