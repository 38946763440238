import React, { useEffect } from "react";
import { Grid, TextField, Button } from "@mui/material"; // Use MUI's Button
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, register } from "../../State/Auth/Action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegistrationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");
  const { auth } = useSelector((store) => store);

  console.log("auth ",auth)

  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt));
    }
  }, [jwt, auth.jwt, dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const email = data.get("email");
    const mobile = data.get("mobile");

    // Security check for institute email ID
    if (!email.endsWith("@iitj.ac.in")) {
      toast.error("Only institute email IDs (@iitj.ac.in) are accepted.");
      return;
    }

    // Check for 10-digit mobile number
    if (!/^\d{10}$/.test(mobile)) {
      toast.error("Mobile number must be exactly 10 digits.");
      return;
    }

    const userData = {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email,
      mobile,
      password: data.get("password"),
    };

    dispatch(register(userData))

    setTimeout(() => {
      window.location.reload();
    }, 3000);
    
    if (auth.error===null) {
      toast.success("Registration successful!");
      navigate("/login");
    } else {
      toast.success("Registration successful!");
      navigate("/login"); // Redirect to login page on success
    }
  };
  return (
    <div>
      <form className="m-10 lg:m-auto" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="First Name"
              fullWidth
              autoComplete="given-name"
              sx={{
                "& .MuiInputLabel-root": {
                  textDecoration: "none",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Last Name"
              fullWidth
              autoComplete="family-name"
              sx={{
                "& .MuiInputLabel-root": {
                  textDecoration: "none",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              placeholder="Only institute email IDs are accepted (@iitj.ac.in)"
              fullWidth
              autoComplete="email"
              sx={{
                "& .MuiInputLabel-root": {
                  textDecoration: "none",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="mobile"
              name="mobile"
              label="Mobile"
              placeholder="Enter a 10-digit mobile number"
              fullWidth
              inputProps={{ maxLength: 10, pattern: "[0-9]{10}" }}
              autoComplete="tel"
              sx={{
                "& .MuiInputLabel-root": {
                  textDecoration: "none",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              autoComplete="new-password"
              sx={{
                "& .MuiInputLabel-root": {
                  textDecoration: "none",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              size="large"
              sx={{
                backgroundColor: "#f1c32f",
                color: "black",
                fontWeight: "bold",
                fontFamily: "Anton, sans-serif",
                py: 1.5,
                "&:hover": {
                  backgroundColor: "#f1f13e",
                },
              }}
            >
              REGISTER
            </Button>
          </Grid>
        </Grid>
      </form>
      <div className="flex justify-center flex-col items-center">
        <div className="py-3 flex items-center">
          <p>If you already have an account?</p>
          <Button
            onClick={() => navigate("/login")}
            variant="text"
            color="secondary"
            sx={{
              ml: 2,
              textDecoration: "underline",
            }}
          >
            Login
          </Button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RegistrationForm;
