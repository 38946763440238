import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import DeliveryAddressForm from "./DeliveryAddressForm";
import OrderSummary from "./OrderSummary";
import Nav from "../Navigation/Navigation/Nav";
import { useNavigate } from "react-router-dom";
import { Payment } from "@mui/icons-material";
import PaymentSuccess from "../Payment/PaymentSuccess";


const steps = ["Login", "Delivery Address", "Order Summary", "Payment"];

export default function Checkout() {
  const location = useLocation();
  const querySearch = new URLSearchParams(location.search);
  const initialStep = parseInt(querySearch.get("step")) || 0; // Get step from URL or default to 0

  const [activeStep, setActiveStep] = React.useState(initialStep);
  const navigate = useNavigate();

  React.useEffect(() => {
    setActiveStep(initialStep); // Update the activeStep if the URL parameter changes
  }, [initialStep]);

  const handleNext = () => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    navigate(`?step=${nextStep}`); // Update URL when moving to the next step
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    setActiveStep(prevStep);
    navigate(`?step=${prevStep}`); // Update URL when going back a step
  };

  return (
    <>
    
      <Nav />
      <div className="mt-10 px-10 lg:px-20">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <PaymentSuccess/>
            </React.Fragment>
          ) : (
            <React.Fragment>

              <div className="mt-10">
                {activeStep == 1 ? <DeliveryAddressForm /> : <OrderSummary />}
              </div>
            </React.Fragment>
          )}
        </Box>
      </div>
    </>
  );
}
