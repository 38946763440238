import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart } from "../../../State/Cart/Action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function createData(category, size) {
  return { category, size };
}

const rows = [
  createData("S", 36),
  createData("M", 38),
  createData("L", 40),
  createData("XL", 42),
  createData("2XL", 44),
];

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [requested, setRequested] = useState(false);
  const [selectedSize, setSelectedSize] = useState("M"); // Default size
  const [openAccordion, setOpenAccordion] = useState(null); // Track which accordion is open
  const { auth } = useSelector((store) => store);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClick = () => {
    if (!auth?.user) {
      toast.warning("Please sign in first to buy now");
      return; // Prevents moving to the next step
    } else {
      const data = {
        productId: product.id,
        size: selectedSize,
        quantity: product.quantity,
        price: product.price,
        discountedPrice: product.discountedPrice,
      };
      dispatch(addItemToCart(data));

      // Navigate to checkout after adding to cart
      navigate("/checkout?step=1");
    }
    // Proceed to the next step if auth.jwt exists
    // For example: navigate("/next-step") or any other action
  };

  useEffect(() => {
    const data = {
      productId: product.id,
      size: selectedSize,
      quantity: product.quantity,
      price: product.price,
      discountedPrice: product.discountedPrice,
    };
    if (requested) {
      dispatch(addItemToCart(data));
      setRequested(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [requested, dispatch]);

  const toggleAccordion = (section) => {
    setOpenAccordion(openAccordion === section ? null : section);
  };

  const ArrowIcon = ({ isOpen }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className={`bi bi-chevron-down transition-transform ${
        isOpen ? "rotate-180" : ""
      }`}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1.5 5.5a.5.5 0 0 1 .708 0L8 10.793l5.793-5.293a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );

  return (
    <div className="item grid grid-cols-1 lg:grid-cols-2 gap-4 rounded-lg p-4 md:p-6 shadow-lg md:mb-12">
      {/* Image Column */}
      <div className="flex justify-center items-center w-full">
        <img
          className="w-full h-auto md:w-7/12 md:h-8/12 lg:object-contain rounded-lg"
          src={product.imageUrl}
          alt="Product"
        />
      </div>

      {/* Product Details Column */}
      <div className="flex flex-col justify-center items-center lg:items-start text-gray-900 space-y-3 md:space-y-5">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold text-center lg:text-left">
          {product.title}
        </h2>
        <p className="text-lg mt-2 font-medium text-center lg:text-left">
          Price:
          <span className="text-red-700 font-semibold">
            {" -"}{Math.round(((product.price - product.discountedPrice) / product.price) * 100)}{"%"}
          </span>
          <span className="text-gray-900 text-xl md:text-2xl lg:text-3xl font-extrabold ml-1">
            ₹{product.discountedPrice}
          </span>
          <span className="text-gray-700 text-md line-through ml-2">
            ₹{product?.price}
          </span>
          <span className="bg-red-700 text-white text-xs md:text-sm font-light rounded-lg py-1 px-2 ml-2">
            Limited Time Deal
          </span>
        </p>

        {/* Size Selection */}
        <div className="flex flex-wrap space-x-2 my-3 text-gray-900">
          <span className="text-lg font-semibold mr-2">Size:</span>
          {["S", "M", "L", "XL", "2XL"].map((size) => (
            <button
              key={size}
              className={`px-3 py-1 rounded ${
                selectedSize === size
                  ? "bg-gray-800 text-white"
                  : "bg-gray-100 text-gray-800"
              } hover:bg-gray-700 hover:text-white transition`}
              onClick={() => setSelectedSize(size)}
            >
              {size}
            </button>
          ))}
        </div>
        <div className="flex flex-row items-center space-x-4">
  <div>
    <Button
      onClick={handleOpen}
      sx={{
        fontWeight: "600",
        fontSize: {
          xs: "0.8rem", // Mobile
          sm: "1rem", // Tablets
          md: "0.9rem", // Laptops and larger
        },
        textAlign: "center",
        color: "black",
        // backgroundColor: "white",
        border: "1px solid black",
        borderRadius: "8px",
        display: {
          xs: "block", // Centered block for mobile
          sm: "inline-flex", // Inline on larger screens
        },
        padding: "0.5rem 1rem",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
        mx: {
          xs: "auto", // Center on mobile
          sm: "0",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.05)", // Light hover effect
        },
      }}
    >
      Size Chart
    </Button>
  </div>
  <span className="text-gray-800 font-semibold align-middle">
    Est. Delivery: <span className="font-bold">Nov '23</span>
  </span>
</div>


        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: "bold",
                fontSize: {
                  xs: "1.2rem", // Mobile
                  sm: "1.5rem", // Tablets
                  md: "1.8rem", // Laptops and larger
                },
                textAlign: "center",
              }}
            >
              Size Chart
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 200 }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Category
                      </TableCell>
                      {rows.map((row) => (
                        <TableCell key={row.category} align="center">
                          {row.category}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Size (inches)
                      </TableCell>
                      {rows.map((row) => (
                        <TableCell key={row.size} align="center">
                          {row.size}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          </Box>
        </Modal>

        {/* Add to Cart and Buy Now Buttons */}
        <div className="flex flex-wrap space-x-2 mt-4">
          <button
            className="w-full md:w-36 lg:w-52 h-10 bg-gray-100 text-gray-900 border-2 border-gray-800 rounded-full font-medium hover:bg-gray-700 hover:text-white transition"
            onClick={() => setRequested(true)}
          >
            Add to Cart
          </button>
          <button
            className="w-full md:mt-0 lg:mt -0 mt-2 md:w-36 lg:w-52 h-10 bg-gray-800 text-white rounded-full font-medium hover:bg-gray-700 transition"
            onClick={handleClick}
          >
            BUY NOW
          </button>
        </div>

        {/* Accordion for Product Details */}
        <div className="w-full mt-4 lg:w-[500px] md:w-[500px]">
          {/* Product Details */}
          <div className="border-b border-gray-500">
            <button
              className="flex justify-between items-center w-full text-left p-2 md:p-3 font-semibold text-gray-800 text-base lg:text-lg"
              onClick={() => toggleAccordion("productDetails")}
            >
              Product Details
              <ArrowIcon isOpen={openAccordion === "productDetails"} />
            </button>
            {openAccordion === "productDetails" && (
              <div className="p-2 md:p-3 text-gray-800 text-sm bg-yellow-100 rounded">
                <p>
                  <strong>Material & Care:</strong> 100% Cotton, Machine Wash, {product.description}
                </p>
                <p>
                  <strong>Country of Origin:</strong> India
                </p>
                <p>
                  <strong>Manufactured & Sold By:</strong> IITIANvibes Pvt.
                  Ltd., IIT Jodhpur
                </p>
                <p>Tel: +91-8233014848</p>
                <p>Email: connect.iitianvibes@gmail.com</p>
                <p>Customer care no.: +91 7489467045</p>
                <p>
                  <strong>Product Description:</strong> Official Licensed
                  IITIANVibes product.
                </p>
              </div>
            )}
          </div>

          {/* Artist's Details */}
          <div className="border-b border-gray-500">
            <button
              className="flex justify-between items-center w-full text-left p-2 md:p-3 font-semibold text-gray-800 text-base lg:text-lg"
              onClick={() => toggleAccordion("artistDetails")}
            >
              Artist's Details
              <ArrowIcon isOpen={openAccordion === "artistDetails"} />
            </button>
            {openAccordion === "artistDetails" && (
              <div className="p-2 md:p-3 text-gray-800 text-sm bg-yellow-100 rounded">
                <p>
                  Discover merchandise that resonates with the IITian spirit and
                  connects you to a world of intellect, passion, and resilience.
                </p>
              </div>
            )}
          </div>

          {/* The Legacy of IITIAN Vibes */}
          <div className="border-b border-gray-500">
            <button
              className="flex justify-between items-center w-full text-left p-2 md:p-3 font-semibold text-gray-800 text-base lg:text-lg"
              onClick={() => toggleAccordion("legacy")}
            >
              The Legacy of IITIAN Vibes
              <ArrowIcon isOpen={openAccordion === "legacy"} />
            </button>
            {openAccordion === "legacy" && (
              <div className="p-2 md:p-3 text-gray-800 text-sm bg-yellow-100 rounded">
                <p>
                  Founded with the vision of uniting the IITJ community, IITIAN
                  Vibes celebrates the spirit of the institute.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
