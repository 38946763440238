import React from "react";
import AdjustIcon from "@mui/icons-material/Adjust";
import { useNavigate } from "react-router-dom";

const OrderCard = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div
      className="border border-gray-300 rounded-lg p-4 mb-4 hover:shadow-lg transition-shadow duration-300 cursor-pointer"
      onClick={() => navigate(`/payment/${item?.id}`)}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") navigate(`/payment/${item?.id}`);
      }}
    >
      <div className="flex flex-col md:flex-row items-start md:items-center">
        {/* Image Section */}
        <div className="flex-shrink-0">
          <img
            className="w-full h-48 md:w-32 md:h-32 object-cover object-top rounded-md"
            src={item.orderItems[0].product.imageUrl}
            alt={item.orderItems[0].product.title}
            loading="lazy"
            onError={(e) => {
              e.target.src = "/path/to/placeholder.png";
            }} // Replace with your placeholder path
          />
        </div>

        {/* Details Section */}
        <div className="mt-4 md:mt-0 md:ml-6 flex-1">
          <div className="flex flex-col space-y-2">
            <div className="text-lg font-semibold text-gray-700">
              {item.orderItems[0].product.title}
            </div>
            <div className="text-sm opacity-50 font-semibold">
              {item.orderItems[0].product.brand}
            </div>
            <div className="text-sm opacity-50 font-semibold">
              Qty: {item.orderItems[0].quantity}
            </div>

            <p className="text-lg font-bold text-gray-800">
              Price: ₹{item.totalDiscountedPrice}
            </p>

            <div className="mt-3">
              <p className="flex items-center text-green-600">
                <AdjustIcon
                  sx={{ width: "15px", height: "15px" }}
                  className="mr-2"
                />
                <span>{item?.orderStatus}</span>
              </p>

              {/* Conditional message based on orderStatus */}
              <p className="text-xs text-gray-500">
                {item?.orderStatus === "PLACED"
                  ? "Your order will be shipped within two weeks"
                  : item?.orderStatus === "PENDING"
                  ? "Your order is pending"
                  : "Your Item Has Been Delivered"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
