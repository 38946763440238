import React, { useState, Fragment } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { navigation } from "../Navigation/Navigation/navigation"; // Adjust the import path accordingly

const TermsAndCondition = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Mobile Menu */}
      <Transition.Root show={openMenu} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setOpenMenu}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4 pt-5 pb-2">
                  <h2 className="text-lg font-medium text-gray-900">Menu</h2>
                  <button
                    type="button"
                    className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                    onClick={() => setOpenMenu(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Navigation Links */}
                <div className="mt-6">
                  {navigation.mobilepages.map((page) => (
                    <a
                      key={page.name}
                      href={page.href}
                      className="block px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-100"
                      onClick={() => setOpenMenu(false)} // Close menu on link click
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Header Section */}
      <header className="bg-yellow-500 w-full h-16 lg:h-24 mb-8 flex items-center justify-between px-4 lg:px-14 shadow-md">
        {/* Hamburger Menu (Visible on Mobile) */}
        <div className="flex lg:hidden">
          <button
            onClick={() => setOpenMenu(true)}
            className="text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
        </div>

        {/* Back Arrow (Visible on Desktop) */}
        <div className="hidden lg:flex items-center">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: { xs: 30, sm: 40, md: 50, lg: 60 } }}
              className="text-black mr-2"
            />
            <span className="sr-only">Back</span>
          </button>
        </div>

        {/* Page Title */}
        <span className="text-black font-extrabold text-2xl sm:text-3xl lg:text-5xl tracking-wider text-center flex-1">
          TERMS & CONDITIONS
        </span>

        {/* Placeholder for Alignment */}
        <div className="hidden lg:flex" style={{ width: "40px" }}></div>
      </header>

      {/* Terms Content Section */}
      <main className="flex-1 bg-gray-100 px-4 sm:px-8 lg:px-16 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Terms Content */}
          <div className="bg-gray-100 p-6 lg:p-8 rounded-lg shadow-md">
            <p className="font-semibold text-sm lg:text-lg">
              The domain name{" "}
              <a
                href="https://www.iitianvibes.com"
                className="text-blue-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.iitianvibes.com
              </a>{" "}
              is operated by IITianvibes. IITIAN Vibes empowers artists to
              monetize creativity by making artwork available for sale across
              various products while allowing artists to retain full creative
              rights. By using our services on{" "}
              <a
                href="https://www.iitianvibes.com"
                className="text-blue-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.iitianvibes.com
              </a>
              , you agree to these Terms of Service, conditions, and policies
              (“Terms of Service”).
            </p>

            <h2 className="text-lg lg:text-2xl font-bold mt-8 border-b-2 border-[#76520E] pb-2 text-[#76520E]">
              CONTENT & COPYRIGHT POLICY
            </h2>
            <p className="text-sm lg:text-lg mt-4">
              IITIAN Vibes understands that all information, images, data, text,
              music, sound, graphics, videos, messages, or other materials,
              whether publicly posted or privately transmitted, are the
              exclusive work and property of the person from whom such Content
              originated. IITIAN Vibes does not claim permanent ownership of
              your Content. You retain copyright and any other rights you hold
              in the Content that you submit, post, upload, display, or sell on
              or through IITIAN Vibes.
            </p>
            <p className="text-sm lg:text-lg mt-4">
              IITIAN Vibes cannot manually screen all Content before display and
              reserves the right to remove Content that breaches this Agreement
              or is objectionable. You, not IITIAN Vibes, are entirely
              responsible for all Content you upload or make available. You
              understand that you may be exposed to Content that is offensive,
              indecent, or objectionable and waive any legal rights against
              IITIAN Vibes regarding this.
            </p>

            <h2 className="text-lg lg:text-2xl font-bold mt-8 border-b-2 border-[#76520E] pb-2 text-[#76520E]">
              LIABILITY
            </h2>
            <p className="text-sm lg:text-lg mt-4">
              You agree not to hold IITIAN Vibes responsible for other users'
              Content, actions, or collaborations. IITIAN Vibes is not liable
              for the quality, safety, or legality of collaborations advertised.
              We cannot guarantee continuous access to our services and exclude
              implied warranties, terms, and conditions related to service use.
              Some jurisdictions do not allow disclaimer exclusions, so these
              may not apply to you.
            </p>
            <p className="text-sm lg:text-lg mt-4">
              You are responsible for taking precautions to avoid interference,
              such as viruses, while using the website. IITIAN Vibes accepts no
              responsibility for any such interference or damage to your system.
            </p>

            <h2 className="text-lg lg:text-2xl font-bold mt-8 border-b-2 border-[#76520E] pb-2 text-[#76520E]">
              TRADEMARKS
            </h2>
            <p className="text-sm lg:text-lg mt-4">
              Use of IITIAN Vibes' trademarks requires prior written permission
              and must not mislead or imply association without authorization.
              Unauthorized use of our trademarks in any misleading or
              disparaging way is prohibited.
            </p>

            <h2 className="text-lg lg:text-2xl font-bold mt-8 border-b-2 border-[#76520E] pb-2 text-[#76520E]">
              COMMUNICATION
            </h2>
            <p className="text-sm lg:text-lg mt-4">
              By using IITIAN Vibes' services, you agree to receive promotional
              or informational content relevant to IITIAN Vibes through SMS,
              email, or both. Once you place an order with us, you are
              automatically subscribed to order-related notifications via
              WhatsApp.
            </p>

            <h2 className="text-lg lg:text-2xl font-bold mt-8 border-b-2 border-[#76520E] pb-2 text-[#76520E]">
              ACCOUNT TERMINATION
            </h2>
            <p className="text-sm lg:text-lg mt-4">
              IITIAN Vibes reserves the right to limit, suspend, or terminate
              accounts that violate these terms. Unconfirmed or inactive
              accounts may also be canceled without prior notice.
            </p>

            <h2 className="text-lg lg:text-2xl font-bold mt-8 border-b-2 border-[#76520E] pb-2 text-[#76520E]">
              GOVERNING LAW
            </h2>
            <p className="text-sm lg:text-lg mt-4">
              These Terms & Conditions are governed by applicable laws. By using
              the website, you agree to comply with all applicable local laws
              regarding your use of our services.
            </p>

            {/* Additional sections could be added here as needed */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default TermsAndCondition;
